import Vue from 'vue'
import { decode, handleize } from '@/lib/util'
import { getRecentlyViewed } from '@/lib/recently-viewed'
import nanoajax from 'nanoajax'
import { Swiper } from 'swiper'

export default el => new Vue({
  name: 'Product Carousel',
  el,
  data () {
    return {
      options: {},
      selectedIndex: 0,
      recentlyViewedProducts: [],
      enableRecentlyViewed: false,
      exceptedProductHandle: '',
      recentlyCarousel: null,
      alsoLikeCarousel: null
    }
  },
  methods: {
    handleize,
    onChangeTab(index) {
      this.selectedIndex = index
      window.scrollAnimations.reset()
    },
    initRecentlyCarousel() {
      this.recentlyCarousel = new Swiper(this.$refs.recentlyCarousel, this.options)
    },
    initAlsoLikeCarousel() {
      this.alsoLikeCarousel = new Swiper(this.$refs.alsoLikeCarousel, this.options)
    }
  },
  mounted() {
    this.enableRecentlyViewed = this.$el.dataset.enableRecentlyViewed
    this.exceptedProductHandle = this.$el.dataset.exceptedProductHandle
    this.exceptedProductHandle = this.$el.dataset.exceptedProductHandle
    this.options = JSON.parse(decode(this.$el.dataset.options))

    if (this.$refs.alsoLikeCarousel) {
      this.initAlsoLikeCarousel()
    }

    if (this.enableRecentlyViewed) {
      const host = `//${window.location.host}/${window.Shopify.routes.root}`
      const recentlyViewed = getRecentlyViewed()
      const query = recentlyViewed
        .filter((handle) => handle !== this.exceptedProductHandle)
        .map((handle) => `handle:${handle}`).join(' OR ')
      nanoajax.ajax({
        url: `${host}/search?view=recently-viewed-endpoint&q=${query}&type=product`,
        method: 'GET',
        cors: true
      }, (code, res) => {
        if (Number(code) !== 200) {
          return
        }
        const decoded = JSON.parse(res)
        this.recentlyViewedProducts = decoded.products || []
        if (this.recentlyViewedProducts && this.recentlyViewedProducts.length) {
          this.$nextTick(() => {
            if (!this.recentlyCarousel) {
              this.initRecentlyCarousel()
            }
          })
        }
      })
    }
  },
})
